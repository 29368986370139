import React, { useEffect } from 'react';
import Layout from '../layouts/layout';

const NotFoundPage = () => {
	// useEffect(() => {
	// 	window.plausible('404', { props: { href: window.location.href } });
	// });

	return (
		<Layout title="Not found">
			<div>
				<h1>NOT FOUND</h1>
				<p>
					You just hit a route that doesn&#39;t exist... the sadness.
				</p>
			</div>
		</Layout>
	);
};

export default NotFoundPage;
